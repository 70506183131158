import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import TechLevelsTable from './TechLevelsTable';
import './TechSkillsTab.scss';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../store';
import { FC, useEffect, useState } from 'react';
import {
  Collaborator1,
  CollabSkillHistory,
} from '../../../../../../store/Collaborators/collaborators.type';
import { Skill1 } from '../../../../../../store/Skills/skills.type';
import { bindActionCreators } from 'redux';
import { getCategories, getKinds } from '../../../../../../store/Skills/skills.actions';
import { ThunkDispatch } from 'redux-thunk';
import { formatDBText } from '../../../../../../helpers/formattingFunctions';
import {
  editSkillLevel,
  setShowEditLvlModal,
} from '../../../../../../store/Collaborators/collaborators.actions';
import SimpleModal from '../../../../../../commons/SimpleModal/SimpleModal';
import KnowledgeButton from '../../../../../../commons/KnowledgeButton/KnowledgeButton';
import TopImg from '../../../../../../assets/typeTOP.svg';
import CoreImg from '../../../../../../assets/typeCORE.svg';
import OtherImg from '../../../../../../assets/typeOTHER.svg';
import { type } from '../../../../../../utils/skillsType';

interface LinkStateProps {
  collaborators1: Collaborator1[];
  collaborator1?: Collaborator1;
  skills1: Skill1[];
  categories: { id: number; name: string }[];
  kinds: { id: number; name: string }[];
  showEditLvlModal: boolean;
  user?: any;
}

interface LinkDispatchProps {
  getCategories: () => void;
  getKinds: () => void;
  setShowEditLvlModal: (showEditLvlModal: boolean) => void;
  editSkillLevel: (info: {
    level: number;
    skillId: number;
    collaboratorId: number;
    commenterEmail: string;
    comment: string;
  }) => void;
}

type Props = LinkDispatchProps & LinkStateProps;

const TechSkillsTab: FC<Props> = ({
  collaborators1,
  collaborator1,
  skills1,
  categories,
  kinds,
  showEditLvlModal,
  user,
  getCategories,
  getKinds,
  setShowEditLvlModal,
  editSkillLevel,
}) => {
  useEffect(() => {
    getCategories();
    getKinds();
    setShowEditLvlModal(false);
  }, []);

  const columns = [
    { id: 'name', title: 'Skill name' },
    { id: 'level', title: 'Level' },
    { id: 'category', title: 'Category' },
    { id: 'kind', title: 'Kind' },
    { id: 'type', title: 'Type' },
    // { id: 'learningTrack', title: 'Learning Track' },
  ];

  const collapsibleColumns = [
    { id: 'date', title: 'Date' },
    { id: 'skillLevel', title: 'Skill level' },
    { id: 'comment', title: 'Justification' },
    { id: 'by', title: 'By' },
  ];

  const techSkillsLvls = [
    { value: 1, name: 1 },
    { value: 2, name: 2 },
    { value: 3, name: 3 },
    { value: 4, name: 4 },
  ];

  const levelPercentage = (level: number) => {
    switch (level) {
      case 1:
        return 25;
      case 2:
        return 50;
      case 3:
        return 75;
      case 4:
        return 100;
      default:
        break;
    }
  };

  const levels = (sk: CollabSkillHistory) => {
    const sortedLevels = [...sk.collaboratorSkillLevelsHistory].sort(
      (a, b) => b.level - a.level
    );

    return sortedLevels[0].level;
  };

  const techSkills = [...collaborator1!.collaboratorSkillHistory]
    .filter((s) => type(s, skills1) !== 'SOFT')
    .sort((a, b) => levels(b) - levels(a));

  const topTen = techSkills.slice(0, 10);

  const topSkills = techSkills.filter((s) => type(s, skills1) === 'TOP');
  const coreSkills = techSkills.filter((s) => type(s, skills1) === 'CORE');
  const otherSkills = techSkills.filter((s) => type(s, skills1) === 'OTHER');

  const tableTechSkills = techSkills.map((skill) => {
    let skillInfo: Skill1 = skills1.filter((s) => s.id === skill.skillId)[0];
    let level = levels(skill);
    const kind =
      kinds.length > 0 && kinds.filter((k) => k.id === skillInfo.skillKindId)[0].name;
    const category =
      categories.length > 0 &&
      categories.filter((c) => c.id === skillInfo.skillCategoryId)[0].name;

    const levelsChange = skill.collaboratorSkillLevelsHistory.map((lvl) => {
      return {
        date: lvl.createdDate?.slice(0, 10),
        skillLevel: lvl.level,
        comment: lvl.comment,
        by: lvl.commenterName,
      };
    });

    let skillTable = {
      skillId: skill.skillId,
      name: skillInfo.name,
      level: level,
      kind: kind,
      category: category,
      type: formatDBText(type(skill, skills1)!),
      levelsChange: [...levelsChange].sort((a, b) => b.skillLevel - a.skillLevel),
    };

    return skillTable;
  });

  const [levelSelected, setLevelSelected] = useState<number>(0);
  const [commentLevel, setCommentLevel] = useState<string>('');
  const [skillIdEdit, setSkillIdEdit] = useState<number>(0);

  const handleSubmit = () => {
    const skill = {
      level: levelSelected,
      skillId: skillIdEdit,
      collaboratorId: collaborator1!.id,
      commenterEmail: user.email,
      comment: commentLevel,
    };

    editSkillLevel(skill);
  };

  return (
    <>
      <SimpleModal
        show={showEditLvlModal}
        handleClose={() => setShowEditLvlModal(false)}
        title={'Level editing'}
        height="365px"
        width="565px"
      >
        <FormControl fullWidth>
          <InputLabel>Skill Level</InputLabel>
          <Select
            value={levelSelected}
            label="Skill Level"
            onChange={(event) => setLevelSelected(Number(event.target.value))}
          >
            {techSkillsLvls.map((tsl, idx) => (
              <MenuItem key={idx} value={tsl.value}>
                {tsl.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          multiline
          rows={4}
          label="Justification"
          value={commentLevel}
          inputProps={{ maxLength: 400 }}
          onChange={(e) => setCommentLevel(e.target.value)}
        />
        <div className="modalBtns_container">
          <KnowledgeButton
            type={'primary'}
            variant="outlined"
            onClick={() => {
              setLevelSelected(0);
              setCommentLevel('');
              setShowEditLvlModal(false);
            }}
          >
            CANCEL
          </KnowledgeButton>
          <KnowledgeButton
            type={'primary'}
            variant="contained"
            onClick={() => {
              handleSubmit();
              setShowEditLvlModal(false);
              setLevelSelected(0);
              setCommentLevel('');
            }}
          >
            SAVE
          </KnowledgeButton>
        </div>
      </SimpleModal>
      <div className="skills__container">
        <h1>Collaborator's top 10</h1>
        <div className="linearProgress__container">
          {topTen.map((skill, idx) => {
            let skillToShow: Skill1 = skills1.filter((s) => s.id === skill.skillId)[0];

            return (
              <Grid container key={idx} sx={styles.container}>
                <Grid item md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <h2>{skillToShow.name}</h2>
                </Grid>
                <Grid item md={5}>
                  <LinearProgress
                    variant="determinate"
                    value={levelPercentage(levels(skill))}
                    style={styles.linearProgress}
                  />
                </Grid>
                <Grid item md={2} sx={{ display: 'flex', alignContent: 'center' }}>
                  <h2>Lvl {levels(skill)}</h2>
                  <img
                    src={require(`../../../../../../assets/type${type(
                      skill,
                      skills1
                    )}.svg`)}
                    alt="skillType"
                    style={styles.skillTypeImgLinear}
                  />
                </Grid>
              </Grid>
            );
          })}
        </div>
      </div>
      <div className="profileTabContainer">
        <h1>Tech Skills Learned</h1>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <img src={TopImg} alt="skillType" style={styles.skillTypeImg} />
          <h2 style={{ margin: 0 }}>Top Skills: {topSkills.length} de 91</h2>
          <img src={CoreImg} alt="skillType" style={styles.skillTypeImg} />
          <h2 style={{ margin: 0 }}>Core Skills:{coreSkills.length} de 37</h2>
          <img src={OtherImg} alt="skillType" style={styles.skillTypeImg} />
          <h2 style={{ margin: 0 }}>Other Skills: {otherSkills.length} de 48</h2>
        </Box>
      </div>

      <div className="skills__container">
        <h1>Tech Skills Information</h1>
        <TechLevelsTable
          columns={columns}
          data={tableTechSkills}
          collapsibleColumns={collapsibleColumns}
          setShowEditLvlModal={setShowEditLvlModal}
          setLevelSelected={setLevelSelected}
          setSkillIdEdit={setSkillIdEdit}
        />
      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    gap: '30px',
  },
  linearProgress: {
    width: '98%',
    height: '20px',
    color: 'var(--DinoPrimary02)',
    background: 'var(--DinoNeutral06)',
    borderRadius: '4px',
    marginTop: '19px',
    border: '1px solid var(--DinoNeutral05) ',
  },
  skillTypeImgLinear: {
    height: '18px',
    width: '18px',
    margin: '0 12px',
    marginTop: '17px',
  },
  skillTypeImg: {
    height: '18px',
    width: '18px',
    margin: '0 12px',
  },
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const { collaborator1, showEditLvlModal, collaborators1 } = state.collaborators;
  const { skills1, categories, kinds } = state.skills;
  const { user } = state.auth;

  return {
    collaborator1,
    showEditLvlModal,
    collaborators1,
    skills1,
    categories,
    kinds,
    user,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({
  getCategories: bindActionCreators(getCategories, dispatch),
  getKinds: bindActionCreators(getKinds, dispatch),
  setShowEditLvlModal: bindActionCreators(setShowEditLvlModal, dispatch),
  editSkillLevel: bindActionCreators(editSkillLevel, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TechSkillsTab);
