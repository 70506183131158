import { FC } from 'react';
import { Collaborator1 } from '../../../../../../../../store/Collaborators/collaborators.type';
import '../../ProfileTabStyle.scss';

interface LaboralInfoProps {
  collaborator: Collaborator1;
  appTranslation?: any;
}

const LaboralInfo: FC<LaboralInfoProps> = (props: LaboralInfoProps) => {
  return (
    <div className="LaboralInfo__grid">
      <h2>Work Email:</h2>
      <h3>{!!props.collaborator.companyEmail ? props.collaborator.companyEmail : '-'}</h3>

      <h2>Active Employee:</h2>
      <h3>{props.collaborator?.isActive ? 'Yes' : 'No'}</h3>

      <h2>Reason for Leaving:</h2>
      <h3>
        {!!props.collaborator?.leavingReason ? props.collaborator.leavingReason : '-'}
      </h3>

      <h2>Start of Hiring:</h2>
      <h3>
        {!!props.collaborator?.startDate
          ? props.collaborator.startDate.slice(0, 10)
          : '-'}
      </h3>

      <h2>Reports to:</h2>
      <h3>{!!props.collaborator?.reportsTo ? props.collaborator.reportsToName : '-'}</h3>
    </div>
  );
};

export default LaboralInfo;
