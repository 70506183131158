import { FC, useEffect } from 'react';
import MenuSelect from './Select/MenuSelect';
import KCLogo from '../../assets/KCLogo.png';
import { Link, useNavigate } from 'react-router-dom';
import './KnowledgeTopNavBar.scss';
import OptionsTopNavBar from './Options/OptionsComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../store/root-reducer';
import { ThunkDispatch } from 'redux-thunk';
//AppRoutes
import AppRoutes from '../../AppRoutes';
import { setAppTranslation } from '../../store/Translations/translations.actions';
import { cleanSession, setUser } from '../../store/Auth/auth.actions';
import {
  setCurrentPath,
  setPathsToShow,
  setShowSideMenu,
} from '../../store/routes/routes.actions';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import SideMenu from '../sidemenu/SideMenu';

import './KnowledgeTopNavBar.scss';

type TopNavBarProps = LinkDispatchProps & LinkStateProps;

const TopNavBar: FC<TopNavBarProps> = (props: TopNavBarProps) => {
  const history = useNavigate();
  const localStore: any = localStorage.getItem('user');

  const {
    currentPath,
    setCurrentPath,
    setPathsToShow,
    user,
    setUser,
    cleanSession,
    setShowSideMenu,
  } = props;

  useEffect(() => {
    if (localStore) {
      setUser(JSON.parse(localStore));
    }
  }, []);

  // useEffect(() => {
  //   if (!user) {
  //     history('login');
  //   }
  // }, [user]);

  const options: {
    description: string;
    patternPath: string;
    redirectPath: string;
  }[] = [
    {
      description: props.appTranslation
        ? props.appTranslation.topNavBar.tabs.COLLABORATORS
        : 'Collaborators',
      patternPath: AppRoutes.collaborators.path,
      redirectPath: AppRoutes.collaborators.professionalServices.fullPath,
    },
    // {
    //   description: props.appTranslation
    //     ? props.appTranslation.topNavBar.tabs.SKILLS
    //     : 'Habilidades',
    //   patternPath: AppRoutes.skills.path,
    //   redirectPath: AppRoutes.skills.techSkills.fullPath,
    // },
    // {
    //   description: props.appTranslation
    //     ? props.appTranslation.topNavBar.tabs.PROJECTS
    //     : 'Proyectos',
    //   patternPath: AppRoutes.projects.path,
    //   redirectPath: AppRoutes.projects.fullPath,
    // },
    // {
    //   description: props.appTranslation
    //     ? props.appTranslation.topNavBar.tabs.CLIENTS
    //     : 'Clientes',
    //   patternPath: AppRoutes.clients.path,
    //   redirectPath: AppRoutes.clients.fullPath,
    // },
    {
      description: props.appTranslation
        ? props.appTranslation.topNavBar.tabs.SETTINGS
        : 'Configurations',
      patternPath: AppRoutes.settings.path,
      redirectPath: AppRoutes.settings.fullPath,
    },
  ];

  function handleOption(op: any, redirect: any) {
    setCurrentPath(op);
    history(`/${redirect}`);
    setPathsToShow({ pathname: `/${redirect}` });
  }

  function logout() {
    cleanSession();
    setCurrentPath(AppRoutes.login.path);
    history(`/${AppRoutes.login.path}`);
    setPathsToShow({ pathname: `/${AppRoutes.login.path}` });
  }

  function handleToggleMenu() {
    props.setShowSideMenu(!props.showSideMenu);
  }

  return user ? (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar disableGutters sx={{ height: '70px' }}>
          {/* TOOD restore side menu if there is need for it */}
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mx: '30px' }}
            onClick={() => handleToggleMenu()}
          >
            <MenuIcon />
          </IconButton> */}
          <Box sx={{ ml: '30px' }}>
            <Link to="/" className="Logo_navBar">
              <img alt="logo" src={KCLogo} className="Logo_navBar_img" />
            </Link>
          </Box>
          <OptionsTopNavBar
            options={options}
            handleOption={handleOption}
            optionSelected={currentPath}
          />
          <MenuSelect
            user={user}
            cleanSession={logout}
            setAppTranslation={props.setAppTranslation}
            appTranslation={props.appTranslation}
          />
        </Toolbar>
      </AppBar>
      <SideMenu />
    </>
  ) : (
    <></>
  );
};

interface LinkStateProps {
  currentPath: string;
  appTranslation?: any;
  user?: any;
  showSideMenu: boolean;
}

interface LinkDispatchProps {
  setCurrentPath: (path: string) => void;
  setPathsToShow: (location: any) => void;
  setUser: (user: any) => void;
  setAppTranslation: (lang: string) => void;
  cleanSession: () => void;
  setShowSideMenu: (showSideMenu: boolean) => void;
}

const mapStateToProps = (state: RootState): LinkStateProps => {
  const { currentPath, showSideMenu } = state.routes;
  const { appTranslation } = state.translations;
  const { user } = state.auth;
  return {
    currentPath,
    showSideMenu,
    appTranslation,
    user,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({
  setCurrentPath: bindActionCreators(setCurrentPath, dispatch),
  setPathsToShow: bindActionCreators(setPathsToShow, dispatch),
  setAppTranslation: bindActionCreators(setAppTranslation, dispatch),
  cleanSession: bindActionCreators(cleanSession, dispatch),
  setUser: bindActionCreators(setUser, dispatch),
  setShowSideMenu: bindActionCreators(setShowSideMenu, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNavBar);
