import { INITIAL_CLIENTS_COUNT, INITIAL_CLIENTS_PAGE } from '../helpers/constants';
import { Client } from '../store/Clients/clients.type';
import { getDefaultHeaders } from './utils.service';

class ClientsService {
  // FIXME use createQuery from utils
  private createParamFilter(filters: any[] = []) {
    let paramFilter: string = '';
    if (filters.length) {
      paramFilter = '';
      filters.forEach((filterData) => {
        let value = filterData.value;
        if (filterData.value !== -1) {
          let filterName = filterData.columnName;
          let condition = '$eq';
          if (filterName === 'name') {
            condition = '$contL';
          } else {
            condition = '$eq';
          }
          paramFilter = `${paramFilter}&filter=${filterName}||${condition}||${value}`;
        }
      });
    }
    return paramFilter;
  }
  async getClients(
    filters?: any[],
    page: number = INITIAL_CLIENTS_PAGE,
    count: number = INITIAL_CLIENTS_COUNT
  ): Promise<any> {
    let queryParams: any = `${process.env.REACT_APP_API_HOST}/clients`;
    if (filters) {
      queryParams = `${
        process.env.REACT_APP_API_HOST
      }/clients?page=${page}&limit=${count}${this.createParamFilter(filters)}`;
    }

    const response = await fetch(`${queryParams}`, {
      method: 'GET',
      headers: getDefaultHeaders(),
    });
    return response.json();
  }

  async addClient(client: Client): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/clients`, {
      method: 'POST',
      body: JSON.stringify(client),
      headers: getDefaultHeaders(),
    });
    return response.json();
  }
  async deleteClient(client: Client): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/clients/${client.id}`,
      {
        method: 'DELETE',
        headers: getDefaultHeaders(),
      }
    );
    return response.json();
  }

  async editClient(client: Client): Promise<any> {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/clients/${client.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(client),
        headers: getDefaultHeaders(),
      }
    );
    return response.json();
  }
}

const clientsService = new ClientsService();
export default clientsService;
