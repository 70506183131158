import { Dispatch } from 'redux';
import { RootState } from '..';

import collaboratorService from '../../services/collaborator.service';
import {
  AreaType,
  Collaborator1,
  JobType,
  Skill,
  SkillTypeColor,
} from './collaborators.type';
import skillsService from '../../services/skills.service';
import collaboratorsSlice from './collaboratorsSlice';
import skillsSlice from '../Skills/skillsSlice';
import moment from 'moment';
import { EnglishLevel } from '../EnglishLevels/englishLevels.type';
//ResumeLink service
import resumeLinksService from '../../services/resumeLinks.service';
import { handleErrors } from '../Auth/auth.actions';
import weightedSkillsService from '../../services/weightedSkills.service';

export const getCollaborators = (
  filters?: any[],
  page: number = 1,
  count: number = 9,
  searchParams?: any[]
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(collaboratorsSlice.actions.requestCollaborators(true));
    const getResponse = await collaboratorService
      .getCollaborators(page, count, filters, searchParams)
      .catch(() => {
        handleErrors(500, dispatch);
      });

    dispatch(
      collaboratorsSlice.actions.getAllCollaborators({
        data: getResponse.data,
        filters: [],
        page: getResponse.page,
        count: getResponse.count,
        pageCount: getResponse.pageCount,
      })
    );
    dispatch(collaboratorsSlice.actions.requestCollaborators(false));
  };
};

export const getCollaboratorsById = (id: any, collaborator?: Collaborator1) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.requestCollaborators(true));
    const getByIdResponse = await collaboratorService
      .getCollaboratorById(id)
      .catch(() => {
        handleErrors(500, dispatch);
      });
    // if (!getByIdResponse) return;
    handleErrors(getByIdResponse.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setCollaborator(getByIdResponse));
    dispatch(collaboratorsSlice.actions.requestCollaborators(false));
  };
};

export const getSeniorities = () => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.requestCollaborators(true));
    const getResponse = await collaboratorService.getSeniorities().catch(() => {
      handleErrors(500, dispatch);
    });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setSeniorities(getResponse));
    dispatch(collaboratorsSlice.actions.requestCollaborators(false));
  };
};

export const getAreas = () => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.requestCollaborators(true));
    const getResponse = await collaboratorService.getAreas().catch(() => {
      handleErrors(500, dispatch);
    });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setAreas(getResponse));
    dispatch(collaboratorsSlice.actions.requestCollaborators(false));
  };
};

export const getTeams = () => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.requestCollaborators(true));
    const getResponse = await collaboratorService.getTeams().catch(() => {
      handleErrors(500, dispatch);
    });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setTeams(getResponse));
    dispatch(collaboratorsSlice.actions.requestCollaborators(false));
  };
};

export const getConditions = () => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.requestCollaborators(true));
    const getResponse = await collaboratorService.getConditions().catch(() => {
      handleErrors(500, dispatch);
    });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setConditions(getResponse));
    dispatch(collaboratorsSlice.actions.requestCollaborators(false));
  };
};

export const getRoles = () => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.requestCollaborators(true));
    const getResponse = await collaboratorService.getRoles().catch(() => {
      handleErrors(500, dispatch);
    });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setRoles(getResponse));
    dispatch(collaboratorsSlice.actions.requestCollaborators(false));
  };
};

export const setShowEditLvlModal = (showEditLvlModal: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setShowEditLvlModal(showEditLvlModal));
  };
};

export const editSkillLevel = (info: {
  level: number;
  skillId: number;
  collaboratorId: number;
  commenterEmail: string;
  comment: string;
}) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(collaboratorsSlice.actions.requestCollaborators(true));
    const getResponse = await collaboratorService.editSkillLevel(info).catch(() => {
      handleErrors(500, dispatch);
    });
    if (!getResponse) return;
    const getCollab = await collaboratorService
      .getCollaboratorById(getState().collaborators.collaborator1?.id)
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setCollaborator(getCollab));
    dispatch(collaboratorsSlice.actions.requestCollaborators(false));
  };
};

//TODO OLD METHODS REVIEW

// const setupArgs = (lastObject?: any, newObject?: any) => {
//   return {
//     page: (newObject && newObject.page) || (lastObject && lastObject.page) || 1,
//     count: (newObject && newObject.count) || (lastObject && lastObject.count) || 9,
//     filters: (newObject && newObject.filters) || (lastObject && lastObject.filters) || [],
//   };
// };

// export const getCollaborators = (
//   filters?: any[],
//   page: number = 1,
//   count: number = 9
// ) => {
//   return async (dispatch: Dispatch, getState: () => RootState) => {
//     dispatch(collaboratorsSlice.actions.requestCollaborators(true));
//     const lastFilter = getState().collaborators.filters;
//     const filtersObj = setupArgs(lastFilter, { page, count, filters });
//     const getResponse = await collaboratorService
//       .getCollaborators(page, count, filters)
//       .catch(() => {
//         handleErrors(500, dispatch);
//       });
//     if (!getResponse) return;
//     handleErrors(getResponse.statusCode, dispatch);
//     let filteredCollaboratorsJobs: any[] = [];
//     getResponse.data.forEach((collab: any) => {
//       jobTypeLabels.forEach((jobType) => {
//         if (collab.job === jobType.type) {
//           filteredCollaboratorsJobs.push({
//             ...collab,
//             job: jobType.label,
//           });
//         }
//       });
//     });
//     let filteredCollaboratorsSeniority: any[] = [];
//     filteredCollaboratorsJobs.forEach((collab: any) => {
//       collaboratorSeniority.forEach((seniorityType) => {
//         if (collab.seniority === seniorityType.type) {
//           filteredCollaboratorsSeniority.push({
//             ...collab,
//             seniority: seniorityType.label,
//           });
//         }
//       });
//     });
//     let filteredCollaboratorsEnglishLevel: any[] = [];
//     filteredCollaboratorsSeniority.forEach((collab) => {
//       if (!!collab && !!collab.englishLevel) {
//         collaboratorEnglishLevel.forEach((enumEnglishLevel) => {
//           if (collab.englishLevel.name === enumEnglishLevel.type) {
//             collab.englishLevel.name = enumEnglishLevel.label;
//             filteredCollaboratorsEnglishLevel.push({
//               ...collab,
//             });
//           }
//         });
//       } else if (!collab.englishLevel) {
//         filteredCollaboratorsEnglishLevel.push({ ...collab });
//       }
//     });
//     dispatch(
//       collaboratorsSlice.actions.getCollaborators({
//         data: filteredCollaboratorsEnglishLevel,
//         filters: filtersObj,
//         page: getResponse.page,
//         count: getResponse.count,
//         pageCount: getResponse.pageCount,
//       })
//     );
//     dispatch(collaboratorsSlice.actions.requestCollaborators(false));
//   };
// };

// export const getCollaboratorsById = (id: any, lang: string | null = 'es') => {
//   return async (dispatch: Dispatch) => {
//     dispatch(collaboratorsSlice.actions.requestCollaborators(true));
//     const getByIdResponse = await collaboratorService
//       .getCollaboratorsById(id, lang)
//       .catch(() => {
//         handleErrors(500, dispatch);
//       });
//     if (!getByIdResponse) return;
//     handleErrors(getByIdResponse.statusCode, dispatch);
//     dispatch(collaboratorsSlice.actions.setCollaborator(getByIdResponse));
//     dispatch(collaboratorsSlice.actions.requestCollaborators(false));
//   };
// };

// COLLABORATORS CURRICULUM MENU
export const handleShowHistoryModal = (open: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setShowHistoryModal(open));
  };
};

export const getCollaboratorCurriculums = (collaboratorId: number) => {
  return async (dispatch: Dispatch) => {
    const data = await resumeLinksService
      .findResumeByCollaboratorId(collaboratorId)
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!data) return;
    handleErrors(data.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setCollaboratorCurriculums(data));
    dispatch(collaboratorsSlice.actions.setLoading(false));
  };
};

export const clearCollaboratorCurriculums = () => {
  return (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.clearCollaboratorCurriculums());
  };
};

export const deleteresumeLinkById = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setLoading(true));
    const resumeResponse = await resumeLinksService
      .deleteResumeHistoryById(id)
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!resumeResponse) return;
    handleErrors(resumeResponse.statusCode, dispatch);
  };
};

export const handleOpenDeleteModal = (openDeleteModal: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setOpenDeleteModal(openDeleteModal));
  };
};

// COLLABORATORS' FILTER BY FULLNAME, POSITION AND AREA
export const setCollaboratorFullname = (collaboratorName: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setCollaboratorFullname(collaboratorName));
  };
};

export const setPosition = (position: { label: string; type: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setPosition(position));
  };
};

export const setArea = (area: { label: string; type: string }) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setArea(area));
  };
};

export const getAllSkills = () => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.requestCollaborators(true));
    const getResponse = await skillsService.getSkills().catch(() => {
      handleErrors(500, dispatch);
    });
    let techSkills: any[] = [];
    let awsSkills: any[] = [];
    let softSkills: any[] = [];
    let toolsSkills: any[] = [];
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    getResponse.data.forEach((skill: any) => {
      switch (skill.type) {
        case 'TECH':
          techSkills.push({ ...skill, active: false });
          break;
        case 'AWS':
          awsSkills.push({ ...skill, active: false });
          break;
        case 'SOFT':
          softSkills.push({ ...skill, active: false });
          break;
        default:
          toolsSkills.push({ ...skill, active: false });
          break;
      }
    });
    let skillsArray: any[] = [
      {
        skillLabel: 'Tech',
        skillType: 'TECH',
        bgColor: SkillTypeColor.TECH.background,
        bdColor: SkillTypeColor.TECH.border,
        color: SkillTypeColor.TECH.color,
        skills: techSkills,
      },
      {
        skillLabel: 'AWS',
        skillType: 'AWS',
        bgColor: SkillTypeColor.AWS.background,
        bdColor: SkillTypeColor.AWS.border,
        color: SkillTypeColor.AWS.color,
        skills: awsSkills,
      },
      {
        skillLabel: 'Soft',
        skillType: 'SOFT',
        bgColor: SkillTypeColor.SOFT.background,
        bdColor: SkillTypeColor.SOFT.border,
        color: SkillTypeColor.SOFT.color,
        skills: softSkills,
      },
      {
        skillLabel: 'Tools',
        skillType: 'TOOLS',
        bgColor: SkillTypeColor.TOOLS.background,
        bdColor: SkillTypeColor.TOOLS.border,
        color: SkillTypeColor.TOOLS.color,
        skills: toolsSkills,
      },
    ];
    dispatch(collaboratorsSlice.actions.setSkills(skillsArray));
    dispatch(collaboratorsSlice.actions.requestCollaborators(false));
  };
};

export const handleActiveStatus = (skillId: number, skillType: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const currentSkills: any[] = getState().collaborators.skills;
    const setSkillsSelected = (skills: any[]) => {
      let filterSkills: any[] = [];
      skills.forEach((skillType) => {
        skillType.skills.forEach((s: any) => {
          if (s.active) {
            filterSkills.push(s);
          }
        });
      });
      dispatch(collaboratorsSlice.actions.setSkillsSelected(filterSkills));
    };
    switch (skillType) {
      case 'TECH':
        let newTechSkills: any[] = currentSkills[0].skills.map((techSkill: any) =>
          techSkill.id === skillId
            ? {
                ...techSkill,
                active: !techSkill.active,
              }
            : techSkill
        );
        let techSkillsArray: any = {
          ...currentSkills[0],
          skills: newTechSkills,
        };

        const skillsT = [
          techSkillsArray,
          currentSkills[1],
          currentSkills[2],
          currentSkills[3],
        ];
        dispatch(collaboratorsSlice.actions.setSkills(skillsT));
        setSkillsSelected(skillsT);
        break;
      case 'AWS':
        let newAWSskills: any[] = currentSkills[1].skills.map((awsSkill: any) =>
          awsSkill.id === skillId
            ? {
                ...awsSkill,
                active: !awsSkill.active,
              }
            : awsSkill
        );
        let awsSkillsArray: any = {
          ...currentSkills[1],
          skills: newAWSskills,
        };
        const skillsA = [
          currentSkills[0],
          awsSkillsArray,
          currentSkills[2],
          currentSkills[3],
        ];
        dispatch(collaboratorsSlice.actions.setSkills(skillsA));
        setSkillsSelected(skillsA);

        break;
      case 'SOFT':
        let newSoftskills: any[] = currentSkills[2].skills.map((softSkill: any) =>
          softSkill.id === skillId
            ? {
                ...softSkill,
                active: !softSkill.active,
              }
            : softSkill
        );
        let softSkillsArray: any = {
          ...currentSkills[2],
          skills: newSoftskills,
        };
        const skillsS = [
          currentSkills[0],
          currentSkills[1],
          softSkillsArray,
          currentSkills[3],
        ];
        dispatch(collaboratorsSlice.actions.setSkills(skillsS));
        setSkillsSelected(skillsS);

        break;
      default:
        let newToolsSkills: any[] = currentSkills[3].skills.map((toolsSkill: any) =>
          toolsSkill.id === skillId
            ? {
                ...toolsSkill,
                active: !toolsSkill.active,
              }
            : toolsSkill
        );
        let toolsSkillsArray: any = {
          ...currentSkills[3],
          skills: newToolsSkills,
        };

        const skillsTo = [
          currentSkills[0],
          currentSkills[1],
          currentSkills[2],
          toolsSkillsArray,
        ];
        dispatch(collaboratorsSlice.actions.setSkills(skillsTo));
        setSkillsSelected(skillsTo);
        break;
    }
  };
};

export const setConfirmFilter = (confirmFilter: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setConfirmFilter(confirmFilter));
  };
};

export const resetSkillsSelected = () => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.resetSkillsSelected());
  };
};

export const setEnglishLevelsSelected = (englishLevels: string[]) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setEnglishLevelsSelected(englishLevels));
  };
};

export const resetEnglishLevelsSelected = () => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.resetEnglishLevelsSelected());
  };
};

export const resetAreaSelected = () => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.resetAreaSelected());
  };
};

export const resetPositionSelected = () => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.resetPositionSelected());
  };
};

export const setTechSkillsCollab = (techSkillsCollab: Skill[]) => {
  return (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setTechSkillsCollab(techSkillsCollab));
  };
};

export const setSoftSkillsCollab = (softSkillsCollab: Skill[]) => {
  return (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setSoftSkillsCollab(softSkillsCollab));
  };
};
export const setAwsSkillsCollab = (awsSkillsCollab: Skill[]) => {
  return (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setAwsSkillsCollab(awsSkillsCollab));
  };
};

export const setToolsCollab = (toolsCollab: Skill[]) => {
  return (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setToolsCollab(toolsCollab));
  };
};

export const setShowSnackBar = (showSnackBar: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setShowSnackBar(showSnackBar));
  };
};

//EDIT COLLABORATOR INFORMATION SECTION

export const setFullname = (fullname: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setFullname(fullname));
  };
};

export const setDateOfBirth = (dateOfBirth: Date | null) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setDateOfBirth(dateOfBirth));
  };
};

export const setCountry = (country: string | undefined) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setCountry(country));
  };
};

export const setState = (state: string | undefined) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setState(state));
  };
};

export const setCity = (city: string | undefined) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setCity(city));
  };
};

export const setAddress = (adress: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setAddress(adress));
  };
};

export const setPhone = (phone: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setPhone(phone));
  };
};

export const setEmail = (email: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setEmail(email));
  };
};

export const setCollabPosition = (collabPosition: JobType | string) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setCollabPosition(collabPosition));
  };
};

export const setCollabArea = (area: AreaType | string) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setCollabArea(area));
  };
};

export const setSeniority = (seniority: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setSeniority(seniority));
  };
};

export const setShowEditCollabForm = (showEditCollabForm: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setShowEditCollabForm(showEditCollabForm));
    dispatch(collaboratorsSlice.actions.setShowAddBtn(!showEditCollabForm));
  };
};

export const clearInputs = () => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.clearInputs());
  };
};

export const editCollabInfo = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(collaboratorsSlice.actions.requestCollaborators(true));
    const collaboratorId: number = getState().collaborators.collaborator?.id!;
    const fullname: string = getState().collaborators.fullname;
    const dateOfBirth: string | null = getState().collaborators.dateOfBirth;
    const country: string = getState().collaborators.country;
    const state: string = getState().collaborators.state;
    const city: string = getState().collaborators.city;
    const address: string = getState().collaborators.address;
    const phone: string = getState().collaborators.phone;
    const position: string = getState().collaborators.job;
    const area: string = getState().collaborators.area;
    const seniority: string = getState().collaborators.seniority;
    const email: string = getState().collaborators.email;
    const appTranslation: any = getState().translations.appTranslation;

    const removeExtraSpace = (text: string) => {
      let textWithoutSpaces = text.trim().split(/ +/).join(' ');
      return textWithoutSpaces;
    };

    function uppercaseFirstLetterFirstWord(stringToCapitalize: string) {
      const capitalizedString =
        stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);
      return capitalizedString;
    }

    const dateFormat = 'DD-MM-YYYY';
    const cleanedDateOfBirth = moment(dateOfBirth).format(dateFormat);
    const cleanedFullname = uppercaseFirstLetterFirstWord(removeExtraSpace(fullname));
    const cleanedAddress = uppercaseFirstLetterFirstWord(removeExtraSpace(address));
    const cleanedEmail = removeExtraSpace(email);

    const editColabResponse = await collaboratorService
      .editCollaborator({
        id: collaboratorId,
        fullname: cleanedFullname,
        dateOfBirth: cleanedDateOfBirth,
        country,
        state,
        city,
        address: cleanedAddress,
        phone,
        email: cleanedEmail,
        job: position,
        area: area,
        seniority,
      })
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!editColabResponse) return;
    handleErrors(editColabResponse.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setShowSnackBar(true));
    dispatch(
      collaboratorsSlice.actions.setMessage({
        type: 'success',
        text: !!appTranslation
          ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages
              .successful.edit + '.'
          : 'Se ha editado la información.',
        header: !!appTranslation
          ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages.headers
              .SUCCESSFULL_ACTION
          : 'Acción exitosa',
      })
    );
    const getResponse = await collaboratorService
      .getCollaboratorsById(getState().collaborators.collaborator?.id)
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setCollaborator(getResponse));
    dispatch(collaboratorsSlice.actions.clearInputs());
    dispatch(collaboratorsSlice.actions.requestCollaborators(false));
    dispatch(collaboratorsSlice.actions.setShowEditCollabForm(false));
    dispatch(collaboratorsSlice.actions.setShowAddBtn(true));
  };
};

//ENGLISH LEVEL SECTION

export const setEnglishLevel = (englishLevel: EnglishLevel) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setEnglishLevel(englishLevel));
  };
};

export const setShowEnglishLevelForm = (showEnglishLevelForm: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setShowEnglishLevelForm(showEnglishLevelForm));
    dispatch(collaboratorsSlice.actions.setShowEnglishLevelAddBtn(!showEnglishLevelForm));
  };
};

export const setShowAddBtnEnglishLevel = (showEnglishLevelAddBtn: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(
      collaboratorsSlice.actions.setShowEnglishLevelAddBtn(showEnglishLevelAddBtn)
    );
    dispatch(collaboratorsSlice.actions.setEnglishLevel(null));
  };
};

export const addEnglishLevelToCollab = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(collaboratorsSlice.actions.requestCollaborators(true));
    const englishLevelId: number | undefined = getState().collaborators.englishLevel!.id!;
    const collaboratorId: number = getState().collaborators.collaborator?.id!;
    const appTranslation: any = getState().translations.appTranslation;

    const editColabResponse = await collaboratorService
      .editCollaborator({
        id: collaboratorId,
        englishLevel: englishLevelId,
      })
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!editColabResponse) return;
    handleErrors(editColabResponse.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setShowSnackBar(true));
    dispatch(
      collaboratorsSlice.actions.setMessage({
        type: 'success',
        text: !!appTranslation
          ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages
              .successful.confirm + '.'
          : 'se ha cargado nueva información.',
        header: !!appTranslation
          ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages.headers
              .SUCCESSFULL_ACTION
          : 'Acción exitosa',
      })
    );

    dispatch(collaboratorsSlice.actions.setShowEnglishLevelForm(false));
    dispatch(collaboratorsSlice.actions.setShowEnglishLevelAddBtn(true));
    const getResponse = await collaboratorService
      .getCollaboratorsById(getState().collaborators.collaborator?.id)
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setCollaborator(getResponse));
    dispatch(collaboratorsSlice.actions.requestCollaborators(false));
  };
};

export const setFiltersActive = (filtersActive: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(collaboratorsSlice.actions.setFiltersActive(filtersActive));
  };
};

export const addSkillsCollab = (skillsAddCollab: any[]) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(collaboratorsSlice.actions.requestCollaborators(true));
    const collaboratorId: number = getState().collaborators.collaborator?.id!;

    // DATA TO SEND
    const SkillsArray: any[] = skillsAddCollab.map((s: any) => {
      return { level: s.level, skill: s.id, collaborator: collaboratorId };
    });

    const appTranslation: any = getState().translations.appTranslation;

    const wSResp = await weightedSkillsService
      .addWeightedSkills(SkillsArray)
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!wSResp) return;
    handleErrors(wSResp.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setShowSnackBar(true));
    dispatch(
      collaboratorsSlice.actions.setMessage({
        type: 'success',
        text: !!appTranslation
          ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages
              .successful.confirm + '.'
          : 'se ha cargado nueva información.',
        header: !!appTranslation
          ? appTranslation.pages?.COLLABORATORS.collaboratorProfile.alertMessages.headers
              .SUCCESSFULL_ACTION
          : 'Acción exitosa',
      })
    );
    dispatch(skillsSlice.actions.setShowAddBtn(true));
    const getResponse = await collaboratorService
      .getCollaboratorsById(getState().collaborators.collaborator?.id)
      .catch(() => {
        handleErrors(500, dispatch);
      });
    if (!getResponse) return;
    handleErrors(getResponse.statusCode, dispatch);
    dispatch(collaboratorsSlice.actions.setCollaborator(getResponse));
    dispatch(collaboratorsSlice.actions.requestCollaborators(false));
  };
};
