import {
  Outlet,
  useLocation,
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { theme } from './theme/index';
import KnowledgeTopNavBar from './commons/KnowledgeTopNavBar/KnowledgeTopNavBar';
import store from './store';
import { Provider } from 'react-redux';
import AppRoutes from './AppRoutes';
import NotFoundPage from './pages/404Page/404Page';

//LAZY LOADING IMPORTS
import Login from './pages/Login/LoginPage';
import Collaborators from './pages/Collaborators/Grid/Collaborators';
import CollaboratorsProfilePage from './pages/Collaborators/Profile/ProfilePage';
// import NewCollaborator from './pages/NewCollaborator/NewCollaborator';
// import TechSkills from './pages/Skills/TechSkills/TechSkillsPage';
// import SoftSkillsPage from './pages/Skills/SoftSkills/SoftSkillsPage';
// import AwsSkillsPage from './pages/Skills/AwsSkills/AwsSkillsPage';
// import ToolsPage from './pages/Skills/Tools/ToolsPage';
// import ProjectsPage from './pages/Projects/ProjectsPage';
import ClientsPage from './pages/Clients/ClientsPage';
import PrivateRoute from './commons/PrivateRoute/PrivateRoute';
import SettingsPage from './pages/Settings/SettingsPage';

const App = () => {
  const AppProvider = () => {
    const location = useLocation();
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {location.pathname !== '/login' && location.pathname !== '/' && (
            <KnowledgeTopNavBar />
          )}
          <div className="App">
            <section>
              <Outlet />
            </section>
          </div>
        </ThemeProvider>
      </Provider>
    );
  };

  return (
    <GoogleOAuthProvider clientId="977082590618-7vaeold2htsc1mfcvf2qtp08770o4nbv.apps.googleusercontent.com">
      <Router>
        <Routes>
          <Route path="/" element={<AppProvider />}>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Navigate
                    to={AppRoutes.collaborators.professionalServices.fullPath}
                    replace
                  />
                </PrivateRoute>
              }
            />
            {/* Login */}
            <Route
              path={AppRoutes.login.path}
              element={
                <PrivateRoute>
                  <Login />
                </PrivateRoute>
              }
            />
            {/* Collaborators */}
            <Route path={AppRoutes.collaborators.path}>
              <Route
                path={AppRoutes.collaborators.professionalServices.path}
                element={
                  <PrivateRoute>
                    <Collaborators />
                  </PrivateRoute>
                }
              />
              <Route path={AppRoutes.collaborators.professionalServices.path}>
                <Route
                  path={AppRoutes.collaborators.professionalServices.id}
                  element={
                    <PrivateRoute>
                      <CollaboratorsProfilePage />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                path={AppRoutes.collaborators.professionalServices.create}
                element={
                  <PrivateRoute>
                    <NewCollaborator />
                  </PrivateRoute>
                }
              /> */}
              </Route>
              <Route
                path={AppRoutes.collaborators.staffingService.path}
                element={
                  <PrivateRoute>
                    <Collaborators />
                  </PrivateRoute>
                }
              />
            </Route>
            {/* Skills stack */}
            {/* <Route path={AppRoutes.skills.path}>
            <Route
              path={AppRoutes.skills.techSkills.path}
              element={
                <PrivateRoute>
                  <TechSkills />
                </PrivateRoute>
              }
            />
            <Route
              path={AppRoutes.skills.softSkills}
              element={
                <PrivateRoute>
                  <SoftSkillsPage />
                </PrivateRoute>
              }
            />
            <Route
              path={AppRoutes.skills.AWSSkills}
              element={
                <PrivateRoute>
                  <AwsSkillsPage />
                </PrivateRoute>
              }
            />
            <Route
              path={AppRoutes.skills.tools}
              element={
                <PrivateRoute>
                  <ToolsPage />
                </PrivateRoute>
              }
            />
          </Route> */}
            {/* Projects stack */}
            {/* <Route path={AppRoutes.projects.path}>
            <Route
              path={AppRoutes.projects.projects}
              element={
                <PrivateRoute>
                  <ProjectsPage />
                </PrivateRoute>
              }
            />
          </Route> */}
            {/* Clients stack */}
            <Route path={AppRoutes.clients.path}>
              <Route
                path={AppRoutes.clients.clients}
                element={
                  <PrivateRoute>
                    <ClientsPage />
                  </PrivateRoute>
                }
              />
            </Route>
            {/* Settings stack */}
            <Route path={AppRoutes.settings.path}>
              <Route
                path={AppRoutes.settings.settings}
                element={
                  <PrivateRoute>
                    <SettingsPage />
                  </PrivateRoute>
                }
              />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
