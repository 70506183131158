import { FC, useEffect } from 'react';
import KnowledgeContainer from '../../../commons/KnowledgeContainer/KnowledgeContainer';
import { useSEO } from '../../../hooks/useSeo';
import { Avatar, Box, Button, Grid } from '@mui/material';
import './styles.scss';
// import MenuSelect from './components/Select/MenuSelect';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import OptionsComponent from './components/Tabs/TabsComponent';
import {
  CollabLearningTrack,
  Collaborator,
  Collaborator1,
} from '../../../store/Collaborators/collaborators.type';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  getCollaboratorsById,
  handleOpenDeleteModal,
  handleShowHistoryModal,
} from '../../../store/Collaborators/collaborators.actions';
import {
  setCurrentPath,
  setDefaultItems,
  setPathsToShow,
} from '../../../store/routes/routes.actions';
import {
  clearResumeS3Link,
  setShowGeneratePdfPage,
  setCvLanguage,
} from '../../../store/GeneratePDF/generatePDF.action';
import defaultItems from '../../../utils/defaultItems';
// import GeneratePDFPage from './components/GeneratePDFPage/GeneratePDFPage';
import KnowledgeBackdrop from '../../../commons/KnowledgeBackdrop/KnowledgeBackdrop';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import AppRoutes from '../../../AppRoutes';

interface LinkStateProps {
  collaborator?: Collaborator;
  collaborator1?: Collaborator1;
  entityIsLoading: boolean;
  showGeneratePdfPage: boolean;
  showHistoryModal: boolean;
  openDeleteModal: boolean;
  appTranslation?: any;
  cvLanguage: string;
  user?: any;
  seniorities: { id: number; seniorityName: string }[];
  roles: { id: number; roleName: string }[];
}

interface LinkDispatchProps {
  getCollaboratorsById: (id: any, collaborator?: Collaborator1) => void;
  setCurrentPath: (path: string) => void;
  setDefaultItems: (items: any[]) => void;
  setPathsToShow: (location: any, defItems?: any[]) => void;
  setShowGeneratePdfPage: (showGeneratePdfPage: boolean) => void;
  handleShowHistoryModal: (showHistoryModal: boolean) => void;
  handleOpenDeleteModal: (openDeleteModal: boolean) => void;
  clearResumeS3Link: () => void;
  setCvLanguage: (cvLanguage: string) => void;
}

type ProfilePageProps = LinkDispatchProps & LinkStateProps;

const CollaboratorsProfilePage: FC<ProfilePageProps> = (props: ProfilePageProps) => {
  let collabPageTranslation =
    props.appTranslation && props.appTranslation.pages?.COLLABORATORS.collaboratorProfile;

  useSEO(
    '',
    props.appTranslation
      ? collabPageTranslation.windowTitle
      : 'Knowledge Catalog | Perfil del Colaborador'
  );
  const { id } = useParams();

  useEffect(() => {
    props.getCollaboratorsById(id!);
  }, [id]);

  const location: any = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   props.getCollaboratorsById(id!);
  // }, [props.cvLanguage]);

  useEffect(() => {
    let currentPathAux: any = location.pathname.split('/')[1];
    props.setCurrentPath(currentPathAux);
    props.setDefaultItems(
      defaultItems(props.appTranslation && props.appTranslation.sideMenu)
    );
    props.setPathsToShow(
      location,
      defaultItems(props.appTranslation && props.appTranslation.sideMenu)
    );
    props.setShowGeneratePdfPage(false);
    props.handleShowHistoryModal(false);
    props.clearResumeS3Link();
  }, []);

  //UPDATE MENU ITEMS WHEN CHANGING APP IDIOM
  useEffect(() => {
    let currentPathAux: any = location.pathname.split('/')[1];
    props.setCurrentPath(currentPathAux);
    props.setDefaultItems(
      defaultItems(props.appTranslation && props.appTranslation.sideMenu)
    );
    props.setPathsToShow(
      location,
      defaultItems(props.appTranslation && props.appTranslation.sideMenu)
    );
  }, [props.appTranslation]);

  const fullname = props.collaborator1
    ? `${props.collaborator1!.firstName} ${props.collaborator1!.lastName}`
    : '';

  const orderedPositions = [...props.collaborator1?.collaboratorPositions!].sort(
    function (a, b) {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    }
  );

  const orderedLearningTracks = [
    ...props.collaborator1?.collaboratorLearningTracks!,
  ].sort(function (a, b) {
    return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
  });

  const seniority =
    orderedPositions.length > 0 &&
    !!orderedPositions[0].seniorityId &&
    props.seniorities.filter((s) => s.id === orderedPositions[0].seniorityId)[0]
      .seniorityName;

  const learningTrack: false | CollabLearningTrack =
    orderedLearningTracks.length > 0 && orderedLearningTracks[0];

  const rol =
    orderedPositions.length > 0 &&
    !!orderedPositions[0].roleId &&
    props.roles.filter((s) => s.id === orderedPositions[0].roleId)[0].roleName;

  return (
    <>
      {/* {props.showGeneratePdfPage ? (
        <GeneratePDFPage
          collaboratorImg={props.collaborator?.image}
          collaboratorFullName={props.collaborator?.fullname}
          onClickBackButton={() => {
            props.setShowGeneratePdfPage(false);
          }}
          handleSubmit={() => {}}
        />
      ) : ( */}
      <KnowledgeContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
          }}
        >
          <Button
            color="primary"
            variant="text"
            onClick={() => navigate(AppRoutes.common.goBack)}
            startIcon={<ArrowBackRoundedIcon />}
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '20px',
              letterSpacing: '0.02em',
              mb: '24px',
            }}
          >
            REGRESAR
            {/* {props.appTranslation
                      ? collabPageTranslation.actionButtons.back
                      : 'Regresar'} */}
          </Button>
        </Box>
        <div className="topSectionContainer">
          <div className="paddingDiv">
            <Grid container>
              <Grid item>
                <Avatar
                  src={props.collaborator1?.pictureURL}
                  sx={{
                    width: '80px',
                    height: '80px',
                    border: '2px solid var(--DinoPrimary01) ',
                  }}
                  imgProps={{ referrerPolicy: 'no-referrer' }}
                />
              </Grid>
              <Grid
                item
                flexGrow={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingLeft: '30px',
                }}
              >
                <h1 style={{ paddingRight: '10px' }}>{fullname}</h1>
                <h1>&nbsp;&nbsp;|&nbsp;&nbsp;</h1>
                {seniority ? (
                  <>
                    <h2>{rol}</h2>
                    <h1>&nbsp;&nbsp;|&nbsp;&nbsp;</h1>
                    <h2>{seniority}</h2>
                  </>
                ) : (
                  <h2>{rol}</h2>
                )}
              </Grid>
              {/* FIXME add actual learning track name */}

              {/* <Grid
                item
                flexGrow={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  paddingLeft: '30px',
                }}
              >
                <h1 style={{ paddingRight: '10px' }}>{learningTrack && learningTrack.learningTrackId}</h1>
              </Grid> */}

              {/* <Grid
                  item
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <MenuSelect
                    onClickCurriculum={() => props.setShowGeneratePdfPage(true)}
                    onClickHistory={() =>
                      props.handleShowHistoryModal(!props.showHistoryModal)
                    }
                    onClickDeleteModal={() =>
                      props.handleOpenDeleteModal(!props.openDeleteModal)
                    }
                    setCvLanguage={props.setCvLanguage}
                    seeHistory={props.showHistoryModal}
                    deleteModal={props.openDeleteModal}
                    appTranslation={props.appTranslation && collabPageTranslation}
                  />
                </Grid> */}
            </Grid>
          </div>
          <OptionsComponent />
        </div>
      </KnowledgeContainer>
      {/* )} */}
      <KnowledgeBackdrop loading={props.entityIsLoading} />
    </>
  );
};

const mapStateToProps = (state: RootState): LinkStateProps => {
  const {
    collaborator,
    collaborator1,
    entityIsLoading,
    showHistoryModal,
    openDeleteModal,
    seniorities,
    roles,
  } = state.collaborators;
  const { appTranslation } = state.translations;
  const { showGeneratePdfPage, cvLanguage } = state.generatePDF;
  const { user } = state.auth;

  return {
    collaborator,
    collaborator1,
    seniorities,
    roles,
    showGeneratePdfPage,
    entityIsLoading,
    showHistoryModal,
    openDeleteModal,
    appTranslation,
    cvLanguage,
    user,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): LinkDispatchProps => ({
  getCollaboratorsById: bindActionCreators(getCollaboratorsById, dispatch),
  setCurrentPath: bindActionCreators(setCurrentPath, dispatch),
  setDefaultItems: bindActionCreators(setDefaultItems, dispatch),
  setPathsToShow: bindActionCreators(setPathsToShow, dispatch),
  setShowGeneratePdfPage: bindActionCreators(setShowGeneratePdfPage, dispatch),
  handleShowHistoryModal: bindActionCreators(handleShowHistoryModal, dispatch),
  handleOpenDeleteModal: bindActionCreators(handleOpenDeleteModal, dispatch),
  clearResumeS3Link: bindActionCreators(clearResumeS3Link, dispatch),
  setCvLanguage: bindActionCreators(setCvLanguage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorsProfilePage);
