import { FC } from 'react';
import Pagination from '@mui/material/Pagination';
import './KnowledgePagination.scss';

interface KnowledgePaginationProps {
  filters: any[];
  searchParams?: any[];
  getPage: number;
  getPageCount: number;
  getCount: number;
  getFn: (filters: any[], page?: number, count?: number, searchParams?: any[]) => void;
}

const KnowledgePagination: FC<KnowledgePaginationProps> = (
  props: KnowledgePaginationProps
) => {
  const { filters, searchParams, getPageCount, getPage, getFn, getCount } = props;
  return (
    <Pagination
      onChange={(e: any, page: number) => {
        getFn(filters, page, getCount, searchParams);
      }}
      count={getPageCount}
      color="primary"
      page={getPage}
    />
  );
};

export default KnowledgePagination;
