import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import { FC, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useDebouncedCallback } from 'use-debounce';
import React from 'react';
import { styled } from '@mui/material/styles';
import { CondOperator } from '@nestjsx/crud-request';

interface KnowledgeAutocompleteProps {
  items: any[];
  label: string;
  filters: any[];
  searchParams?: any[];
  filterColumnName: string;
  selectedValue: any;
  initialPage: number;
  initialCount: number;
  setSelectedValue: (e: any) => any;
  getFn: (filters: any[], page: number, count: number, searchParams?: any[]) => void;
  filtersActive: boolean;
  setFiltersActive: (filtersActive: boolean) => void;
}

const KnowledgeAutocomplete: FC<KnowledgeAutocompleteProps> = ({
  items,
  label,
  filters,
  searchParams,
  filterColumnName,
  selectedValue,
  initialPage,
  initialCount,
  setSelectedValue,
  getFn,
  filtersActive,
  setFiltersActive,
}) => {
  useEffect(() => {
    if (selectedValue !== '') {
      setFiltersActive(true);
    } else {
      setFiltersActive(false);
    }
  }, [selectedValue]);

  const StyledSearchIcon = styled(SearchIcon)`
    vertical-align: middle;
    color: #393e42;
  `;
  function addSearchIconToEndAdornment(endAdornment: any) {
    const children = React.Children.toArray(endAdornment.props.children);
    children.push(<StyledSearchIcon />);
    return React.cloneElement(endAdornment, {}, children);
  }
  const debouncedGet = useDebouncedCallback((value: string) => {
    if (value) {
      let searchParams: any[] = filters.filter(
        (filt) => filt.columnName !== filterColumnName
      );
      getFn([], initialPage, initialCount, [
        ...searchParams,
        {
          columnName: filterColumnName,
          operation: CondOperator.CONTAINS_LOW,
          value: value,
        },
      ]);
    } else {
      let searchParams: any[] = filters.filter(
        (filterType) => filterType.columnName !== filterColumnName
      );
      getFn([], initialPage, initialCount, searchParams);
    }
    setSelectedValue(value);
  }, 600);

  return (
    <div style={{ width: '100%' }} className="autocomplete">
      <Autocomplete
        forcePopupIcon={false}
        value={selectedValue === undefined ? '' : selectedValue}
        options={items}
        onInputChange={(event, newInputValue) => {
          debouncedGet(newInputValue);
        }}
        sx={{
          height: '52px',
          color: 'red',
          [`& fieldset`]: {
            borderRadius: '7px',
          },
        }}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,

              endAdornment: addSearchIconToEndAdornment(params.InputProps!.endAdornment),
            }}
          />
        )}
      />
    </div>
  );
};

export default KnowledgeAutocomplete;
