import { RequestQueryBuilder } from '@nestjsx/crud-request';

function getDefaultHeaders() {
  const localStore: any = localStorage.getItem('access_token');
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${JSON.parse(localStore)}`,
  };
}

function getDefaultHeadersWithoutCredentials() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}

function getCollaboratorId() {
  return window.sessionStorage.getItem('collaborator_id');
}

/**
 * This method tries to bring to one place the query creation methods scattered through the code.
 * It's a highly dynamic method that churns out a query that crud can understand. It also handles special
 * characters when needed.
 *
 * @param  {number} page? Number of the page you are trying to fetch
 * @param  {number} count? Amount of items you are trying to fetch
 * @param  {{columnName: string, operation: string, value: any}[]} filters? Filters that you need to apply no matter what
 * @param  {{ columnName: string, direction: string }[]} sorting? Sorting that you need to apply to the response
 * @param  {{columnName: string, operation: string, value: any}[]} searchParameters? Search parameters that can or not be applied (like a search bar)
 * @param  {string[]} skipColumns? Columns that you don't want to escape characters with \\ as that can create problems (id columns for example)
 */
function createQuery(
  page?: number,
  count?: number,
  filters?: { columnName: string; operation: string; value: any }[],
  sorting?: { columnName: string; direction: string }[],
  searchParameters?: { columnName: string; operation: string; value: any }[],
  skipColumns?: string[]
) {
  const qb = RequestQueryBuilder.create();

  if (page) {
    qb.setPage(Number(page));
  }

  if (count) {
    qb.setLimit(Number(count));
  }

  let filtersQuery: any[] = [];
  let searchQuery: any[] = [];

  if (filters && filters.length > 0) {
    filters.forEach((filterData) => {
      const field = filterData.columnName;
      const operator = filterData.operation;
      const value = encodeFilterValue(
        skipColumns,
        filterData.columnName,
        filterData.value
      );

      const newFilter = {
        [operator]: value,
      };

      filtersQuery.push({ [field]: newFilter });
    });
  }

  if (searchParameters && searchParameters.length > 0) {
    searchParameters.forEach((searchData) => {
      const field = searchData.columnName;
      const operator = searchData.operation;
      const value = encodeFilterValue(
        skipColumns,
        searchData.columnName,
        searchData.value
      );

      const newFilter = {
        [operator]: value,
      };

      searchQuery.push({ [field]: newFilter });
    });
  }

  if (filtersQuery.length > 0 && searchQuery.length > 0) {
    qb.search({
      $and: [
        ...filtersQuery,
        {
          $or: [...searchQuery],
        },
      ],
    });
  } else if (filtersQuery.length > 0 && searchQuery.length === 0) {
    qb.search({
      $and: [...filtersQuery],
    });
  } else if (filtersQuery.length === 0 && searchQuery.length > 0) {
    qb.search({
      $or: [...searchQuery],
    });
  }

  if (sorting && sorting.length > 0) {
    const sortBy: any[] = [];
    for (const sort of sorting) {
      let { columnName, direction } = sort;
      if (!!direction) {
        sortBy.push({ field: columnName, order: direction.toUpperCase() });
      }
    }
    qb.sortBy(sortBy);
  }

  return qb.query();
}

const encodeFilterValue = (skipColumns: string[] = [], column: string, value: any) => {
  const prefix = '\\';
  if (
    typeof value === typeof '' &&
    !skipColumns.includes(column) &&
    value !== '' &&
    isNaN(Number.parseInt(value))
  ) {
    return prefix + value;
  }
  return value;
};

export {
  getDefaultHeaders,
  getCollaboratorId,
  getDefaultHeadersWithoutCredentials,
  createQuery,
};
