import React, { FC, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import '../KnowledgeTopNavBar.scss';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import {
  alpha,
  Box,
  Button,
  Collapse,
  List,
  ListItemButton,
  styled,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

interface Props {
  appTranslation?: any;
  user?: any;
  setAppTranslation: (lang: string) => void;
  cleanSession: () => void;
}

const MenuSelect: FC<Props> = ({
  appTranslation,
  setAppTranslation,
  cleanSession,
  user,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [checkSpaLang, setCheckSpaLang] = React.useState<Boolean>(false);
  const [checkEngLang, setCheckEngLang] = React.useState<Boolean>(false);

  useEffect(() => {
    if (appTranslation) {
      if (appTranslation.lang === 'ES') {
        setCheckSpaLang(true);
      } else {
        setCheckEngLang(true);
      }
    } else {
      setCheckSpaLang(true);
    }
  }, [appTranslation]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openIdiom, setOpenIdiom] = React.useState(false);
  const handleClickIdiom = () => {
    setOpenIdiom(!openIdiom);
  };

  return (
    <div className="container_menu_select">
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Usuario">
          <div className="IconUser">
            <Button
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
            >
              <div className="menu-select__user--img">
                <p> {user ? user.name : ''} </p>
                {user ? (
                  <img src={user ? user.picture : ''} alt="user-img" />
                ) : (
                  <AccountCircleOutlinedIcon
                    fontSize="large"
                    sx={{ color: ' rgba(242, 244, 245, 1)' }}
                  />
                )}
              </div>

              <KeyboardArrowDownIcon
                sx={{ color: ' rgba(242, 244, 245, 1)', margin: 'auto' }}
              />
            </Button>
          </div>
        </Tooltip>
      </Box>
      <StyledMenu
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose} disableRipple>
          {appTranslation ? appTranslation.topNavBar.userMenu.FILES : 'Archivos'}
        </MenuItem> */}
        <ListItemButton onClick={handleClickIdiom}>
          {appTranslation ? appTranslation.topNavBar.userMenu.LANGUAGE.item : 'Idioma'}
          {openIdiom ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openIdiom} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => {
                handleClose();
                setAppTranslation('ES');
                setCheckSpaLang(true);
                setCheckEngLang(false);
              }}
              sx={{ pl: 4 }}
            >
              {appTranslation
                ? appTranslation.topNavBar.userMenu.LANGUAGE.spanish
                : 'Español'}
              {!!checkSpaLang && (
                <CheckRoundedIcon
                  sx={{ ml: '16px', mb: '6px', color: 'var(--DinoPrimary04)' }}
                />
              )}
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                handleClose();
                setAppTranslation('EN');
                setCheckEngLang(true);
                setCheckSpaLang(false);
              }}
              sx={{ pl: 4 }}
            >
              {appTranslation
                ? appTranslation.topNavBar.userMenu.LANGUAGE.english
                : 'Inglés'}
              {!!checkEngLang && (
                <CheckRoundedIcon
                  sx={{ ml: '16px', mb: '6px', color: 'var(--DinoPrimary04)' }}
                />
              )}
            </ListItemButton>
          </List>
        </Collapse>
        <MenuItem onClick={() => cleanSession()} disableRipple>
          {appTranslation ? appTranslation.topNavBar.userMenu.EXIT : 'Cerrar sesión'}
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 246,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default MenuSelect;
